* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI";
  line-height: 1.5;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
