nav {
  z-index: 6000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: white;
  .logo {
    position: absolute;
    cursor: pointer;
    top: 5px;
    left: 25px;
    img {
      height: 70px;
    }
  }

  #hamburger {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    display: none;
    cursor: pointer;
  }

  .links {
    position: absolute;
    top: 18px;
    right: 25px;
    ul {
      padding: 0;
      margin: 0;
      li {
        padding: 10px;
        display: inline-block;
        a {
          position: relative;
          text-transform: uppercase;
          text-decoration: none;
          user-select: none;
          color: #000;
          transition: 0.5s;
          cursor: pointer;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 3px;
            top: 100%;
            left: 0;
            background: #343a40;
            transition: transform 0.5s;
            transform: scaleX(0);
            transform-origin: center;
          }
          &:hover {
            color: #343a40;
          }

          &:hover::after {
            transform: scaleX(1);
            transform-origin: center;
          }
          &.active::after {
            transform: scaleX(1);
            transform-origin: center;
          }

          &.dis-active.active::after {
            transform: scaleX(0);
            transform-origin: center;
          }
        }
      }
    }
  }
}

@media (max-width: 620px) {
  nav {
    #hamburger {
      display: block;
    }
    .links {
      position: absolute;
      top: 80px;
      left: 0;
      right: 0;
      background-color: rgba(230, 230, 230, 0.8);
      display: none;
      ul {
        li {
          display: block;
          padding: 10px;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

.react-parallax-content {
  > div {
    height: 600px;
    > div {
      background-color: white;
      padding: 20px;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      > h1 {
        margin: 0;
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
        border-bottom: 1px solid #000000;
      }
      > h3 {
        margin: 0;
        font-weight: 400;
      }
    }
  }
}

.parallax-box:nth-child(odd) {
  min-height: 100px;
  color: #f8f9fa;
  background-color: #343a40;
  width: 100%;
  padding: 1rem;
  text-align: center;
  font-size: 1.25rem;
  > h5 {
    margin: 0;
    padding-bottom: 1.5rem;
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  > p {
    max-width: 1000px;
    font-weight: 300;
  }
}

.parallax-box {
  min-height: 100px;
  color: #343a40;
  background-color: #f8f9fa;
  width: 100%;
  padding: 1rem;
  text-align: center;
  font-size: 1.25rem;
  > h5 {
    margin: 0;
    padding-bottom: 1.5rem;
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  > p {
    max-width: 1000px;
    margin: 0 auto;
    font-weight: 300;
  }
}

.gridService {
  display: grid;
  grid-template-columns: auto 286px 10px 286px 10px 286px auto;

  .service-1 {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .service-2 {
    grid-column-start: 4;
    grid-column-end: 5;
  }

  .service-3 {
    grid-column-start: 6;
    grid-column-end: 7;
  }
}

@media screen and (max-width: 930px) {
  .gridService {
    display: block;
    div {
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

.card {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 18rem;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  > img {
    width: 100%;
    vertical-align: middle;
    border-style: none;
  }
  > div {
    flex: 1 1 auto;
    padding: 1.25rem;
    > h5 {
      margin-top: 0;
      margin-bottom: 0.75rem;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.2;
    }

    > p {
      margin: 0;
    }
  }
}

.wrapper {
  position: relative;
  flex-grow: 1;
  margin: auto;
  max-width: 1200px;
  padding-bottom: 15%;
  padding: 0 4% 15% 4%;

  @media screen and (min-width: 1250px) {
    padding-bottom: 180px;
  }

  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 2vmin;
  justify-items: center;
  align-items: center;

  IMG {
    z-index: 1;
    grid-column: span 2;
    max-width: 100%;
    margin-bottom: -52%;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    transform: scale(1);
    transition: all 0.25s;

    &:nth-child(7n + 1) {
      grid-column: 2 / span 2;
    }

    &:hover {
      z-index: 2;
      transform: scale(1.4);
    }
  }
}

.nvs {
  position: relative;
  text-align: right;
  height: 325px;
  width: 100%;
}

#gmap_canvas img {
  max-width: none !important;
  background: none !important;
}

.contactGrid {
  display: grid;
  grid-template-columns: auto 485px 30px 485px auto;
  @media screen and (max-width: 1050px) {
    grid-template-columns: auto 30px auto;
  }
  @media screen and (max-width: 690px) {
    display: block;
    .contact-1 {
      display: none;
    }
  }

  .contact-1 {
    grid-column-start: 2;
    grid-column-end: 3;
    @media screen and (max-width: 1050px) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }

  .contact-2 {
    grid-column-start: 4;
    grid-column-end: 5;
    @media screen and (max-width: 1050px) {
      grid-column-start: 3;
      grid-column-end: 4;
    }
    @media screen and (max-width: 690px) {
      margin-bottom: 20px;
    }
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin: 0;
  > li {
    @media screen and (max-width: 690px) {
      text-align: center;
      &.right {
        text-align: center !important;
      }
    }

    @media screen and (min-width: 690px) {
      &.hidden {
        display: none;
      }
      &:nth-child(3) {
        border: 0;
      }
    }
    list-style-type: disc;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    background: transparent;
    text-align: left;
    display: block;
    margin-bottom: -5px;
    padding: 0.75rem 1.25rem;
    &:last-child {
      border: 0;
    }

    > h5 {
      font-weight: bolder;
      font-size: 1.25rem;
      margin: 0 0 0.5rem 0;
    }

    > p {
      font-weight: 300;
      margin: 0;
      font-size: 1.25rem;
      > a {
        font-weight: 300;
        text-decoration: none;
        color: #343a40;
      }
      > b {
        font-weight: bolder;
      }
    }
    &.right {
      text-align: right;
    }
  }
}

.map-grid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto 1000px auto;
  @media screen and (max-width: 1135px) {
    display: block;
  }
  .map {
    grid-column-start: 2;
    grid-column-end: 3;
    overflow: hidden;
    width: 100%;
    position: relative;
    iframe {
      height: 400px;
      width: 100%;
    }
  }
}

.footerGrid {
  display: grid;
  grid-template-columns: auto 480px 20px 290px 20px 290px auto;
  @media screen and (max-width: 1135px) {
    grid-template-columns: auto auto 20px auto 20px auto auto;
  }
  @media screen and (max-width: 690px) {
    display: block;
    text-align: center;
  }
  text-align: left;
  .footer-1 {
    grid-column-start: 2;
    grid-column-end: 3;
    img {
      width: 300px;
      float: right;
      @media screen and (max-width: 690px) {
        float: none;
        margin-bottom: 10px;
      }
    }
  }

  .footer-2 {
    grid-column-start: 4;
    grid-column-end: 5;
    @media screen and (max-width: 690px) {
      margin-bottom: 10px;
    }
    > h6 {
      font-weight: bolder;
      font-size: 1rem;
      margin: 0 0 0.5rem 0;
    }
    > p {
      font-size: 16px;
      font-weight: 300;
      margin: 0;
      &:nth-child(5) {
        margin-top: 1.5rem;
      }
    }
  }

  .footer-3 {
    grid-column-start: 6;
    grid-column-end: 7;
    > h6 {
      font-weight: bolder;
      font-size: 1rem;
      margin: 0 0 0.5rem 0;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      margin: 0;
      user-select: none;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

#modal {
  display: none;
  z-index: 7000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
  padding: 10px;
  overflow-y: auto;
  #modalAfter {
    position: relative;
    margin: 50px auto;
    padding: 10px;
    background: #fff;
    max-width: 1000px;
    #close {
      position: absolute;
      top: -25px;
      right: 0;
      width: 20px;
      filter: invert(1);
    }
    #modalImage {
      width: 100%;
    }
  }
}

#pfrp {
  display: none;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  @media screen and (min-width: 750px) {
    display: block;
  }
}

#pfrs {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  @media screen and (min-width: 750px) {
    display: none;
  }
}
